<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">
          {{ user.committees.name }} Committee
        </div>
      </v-row>
      <v-row class="">
        <div class="">
          Here you will find all details concerning your committee including
          committee sessions, members and documents.
        </div>
      </v-row>
    </v-col>

    <!-- recent sessions -->
    <div class="pa-2">
      <div class="d-flex">
        <p class="text-h6">Recent Committee Sessions</p>
        <v-spacer></v-spacer>
        <v-text-field
          outlined
          dense
          label="Search"
          prepend-inner-icon="mdi-magnify"
          v-model="search"
        ></v-text-field>
      </div>
      <v-data-table
        class="black--text elevation-10"
        :headers="headers"
        :items="memberCommitteeSessions"
        :search="search"
      >
        <template v-slot:[`item.date`]="{ item }">
          {{ new Date(`${item.date} ${item.time}`) | moment("calendar") }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="primary" class="pa-2" @click="openDocument(item)">
            <v-icon size="30">mdi-file-pdf-box</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>

    <!-- committee members -->
    <div class="pa-2">
      <div class="d-flex">
        <p class="text-h6">Your committee members</p>
        <v-spacer></v-spacer>
        <v-text-field
          outlined
          dense
          label="Search"
          prepend-inner-icon="mdi-magnify"
          v-model="searchMembers"
        ></v-text-field>
      </div>
      <v-data-table
        class="black--text elevation-10"
        :headers="membersHeaders"
        :items="commiteeMembers"
        :search="searchMembers"
      >
      </v-data-table>
    </div>

    <!-- pdf dialog -->
    <v-dialog v-model="pdfDialog">
      <PdfDialog :pdf="pdf" />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { userStore } from "@/stores/user";
import { commiteeStore } from "@/stores/commitees";
import { sessionStore } from "@/stores/sessions";
import PdfDialog from "../PdfViewer.vue";

export default {
  name: "YourCommittee",
  data: () => ({
    search: "",
    headers: [
      {
        text: "date",
        value: "date",
        class: "black--text text-uppercase",
      },
      {
        text: "title",
        value: "name",
        class: "black--text text-uppercase",
      },
      {
        text: "description",
        value: "description",
        class: "black--text text-uppercase",
        width: "40%",
      },
      {
        text: "actions",
        value: "actions",
        class: "black--text text-uppercase",
      },
    ],
    pdfDialog: false,
    pdf: "",
    searchMembers: "",
    membersHeaders: [
      {
        text: "name",
        value: "name",
        class: "text-uppercase black--text",
      },
      {
        text: "email",
        value: "email",
        class: "text-uppercase black--text",
      },
    ],
  }),
  computed: {
    ...mapState(userStore, ["user"]),
    ...mapState(commiteeStore, ["commitees"]),
    ...mapState(sessionStore, ["commiteeSessions"]),
    memberCommitteeSessions() {
      return this.commiteeSessions.filter(
        (c) => c.id === this.user.committee_type_id
      );
    },
    commiteeMembers() {
      return this.commitees.find((c) => c.id === this.user.committee_type_id)
        .users;
    },
  },
  components: {
    PdfDialog,
  },
  methods: {
    ...mapActions(sessionStore, ["getSessions"]),
    openDocument(item) {
      this.pdf = item.files[0].location;
      this.pdfDialog = true;
    },
  },
  created() {
    this.getSessions();
  },
};
</script>

<style></style>
